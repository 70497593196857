// extracted by mini-css-extract-plugin
export var about = "AboutTop-module--about--86ecc";
export var content = "AboutTop-module--content--7c8fb";
export var description = "AboutTop-module--description--93c99";
export var heading = "AboutTop-module--heading--cd8f3";
export var image = "AboutTop-module--image--c3228";
export var imageWrapper = "AboutTop-module--imageWrapper--915e4";
export var no = "AboutTop-module--no--b4c09";
export var secondImage = "AboutTop-module--secondImage--2f0b4";
export var secondText = "AboutTop-module--secondText--13032";
export var textWrapper = "AboutTop-module--textWrapper--817cd";
export var title = "AboutTop-module--title--73978";
export var title_no = "AboutTop-module--title_no--5deaf";