import * as React from "react";
import AboutSecound from "../components/AboutSecound/AboutSecound";
import AboutThird from "../components/AboutThird/AboutThird";
import AboutTop from "../components/AboutTop/AboutTop";
import Seo from "../components/seo";
import Layout from "../layout";

const AboutPage = () => {
  return (
    <Layout>
      <AboutTop />
      <AboutSecound />
      <AboutThird />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => (
  <Seo description="小倉たんはらみについて" title="紹介" />
);
