import * as React from 'react';
import * as styles from './AboutTop.module.css';
import TitleUnderline from '../title_underline/TitleUnderline';

const AboutTop = () => {
  return (
    <div className={styles.about}>
        <TitleUnderline title="当店について" color="#fff" underlineColor="#fff" />
        <h2 className={styles.heading}>小倉たんはらみは<br/>国産牛100%に<br className={styles.title_no}/>こだわっています</h2>
        
        <div className={styles.content}>
            <div className={styles.imageWrapper}>
              <img src="/images/index/about_1.jpg" alt="about_1" className={styles.image} />
            </div>
            <div className={styles.textWrapper}>
              <h2 className={styles.title}>厳選されたタン・ハラミ・サガリ・ホルモンを炭火で堪能</h2>
              <p className={styles.description}>
                私たちは、厳選された牛タン・ハラミ・サガリ・ホルモン肉を<br className={styles.no}/>
                いろりの炭火で焼くといった、当店独自のスタイルで提供します<br/>
                国産タン・ハラミ・サガリ・ホルモンの柔らかな肉質と、口の中に広がる豊かな風味は、一度味わえば忘れられない感動を与えてくれます<br/><br/>

                大切な人との特別な日、そして何気ない日常の中のちょっとした贅沢として国産タンハラミの極上の味わいが、あなたの大切な時間をより一層輝かせます<br/><br/>

                私たちは、お客様に最高のひと時をお過ごしいただくために、100%国産牛にこだわり続けます
              </p>
            </div>
            
        </div>

            

    </div>
  );
};

export default AboutTop;
